"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.edm_test_fleet_provision_inputs = void 0;
exports.edm_test_fleet_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{1,12}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'EDAMS Device Group',
        name: 'EDAMSDeviceGroup',
        description: 'Device Group in EDAMS (ex: Beta, ProdNa) Please note: this value is case-sensitive',
        regex: '^[a-zA-Z0-9\\-]*',
        defaultValue: '',
    },
];
