"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtectedRouter = exports.useAuth = exports.AuthProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const fitjava_script_client_1 = require("@amzn/fitjava-script-client");
const functions_1 = require("../common/functions");
const CONSTANTS = __importStar(require("../common/constants"));
const fit_token_provider_1 = require("./fit_token_provider");
// Create the context with a default mock function
const authContext = react_1.default.createContext({
    isAuthenticated: false,
    setAuthState: (isAuthed) => {
        return;
    },
    getAuthUrl: () => {
        return '';
    },
    storeAuthCode: (authCode) => {
        return;
    },
    getToken: () => {
        return new Promise(() => {
            return;
        });
    },
});
function AuthProvider({ children }) {
    const auth = useProvideAuth();
    return (0, jsx_runtime_1.jsx)(authContext.Provider, { value: auth, children: children });
}
exports.AuthProvider = AuthProvider;
function useAuth() {
    return react_1.default.useContext(authContext);
}
exports.useAuth = useAuth;
function useProvideAuth() {
    const isTokenPresent = !(0, functions_1.isEmptyString)(sessionStorage.getItem(fitjava_script_client_1.Constants.SESSION_STORAGE_ACCESS_TOKEN_KEY)) &&
        !(0, functions_1.isEmptyString)(sessionStorage.getItem(fitjava_script_client_1.Constants.SESSION_STORAGE_REFRESH_TOKEN_KEY)) &&
        !(0, functions_1.isEmptyString)(sessionStorage.getItem(fitjava_script_client_1.Constants.SESSION_STORAGE_ID_TOKEN_KEY));
    const [isAuthenticated, setIsAuthenticated] = (0, react_1.useState)(isTokenPresent);
    const setAuthState = (isAuthed) => {
        setIsAuthenticated(isAuthed);
    };
    const getAuthUrl = () => {
        return fit_token_provider_1.tokenProviderInstance.getAuthUrl();
    };
    const storeAuthCode = (authCode) => {
        fit_token_provider_1.tokenProviderInstance.storeAuthCode(authCode);
    };
    const getToken = () => {
        return fit_token_provider_1.tokenProviderInstance.getToken();
    };
    return { isAuthenticated, setAuthState, getAuthUrl, storeAuthCode, getToken };
}
// If there's no token present, redirect to auth callback page automatically without any auth code
// If auth callback page is accessed without auth code, do the redirection to FIT
function ProtectedRouter({ children, path, ...rest }) {
    const auth = useAuth();
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: path, ...rest, render: ({ location }) => auth.isAuthenticated ? (children) : ((0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: {
                pathname: CONSTANTS.ROUTE_AUTH,
                state: {
                    from: location,
                },
            } })) }));
}
exports.ProtectedRouter = ProtectedRouter;
