"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ephiron_provision_inputs = void 0;
exports.ephiron_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
];
