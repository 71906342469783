"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wigo_provision_inputs = void 0;
exports.wigo_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Application URL',
        name: 'ApplicationUrl',
        description: 'Example: http://wigo-na-iad.iad.proxy.amazon.com/wigo/dock/signage/dockdoor?nodeId=SAT4&dockDoor=DD248&dockDoor=DD247&orient=landscape',
        regex: '.*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Wireless Type',
        name: 'FCType',
        description: 'Type determines wireless properties: ssid, interface, validfor. Ref: https://code.amazon.com/packages/EdgeDeviceManagementScripts/blobs/mainline/--/scripts/Provisioning/WIGO/wireless_config.json',
        regex: '^[a-zA-Z0-9\\-\\_]*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group-optional',
        label: 'Display Resolution',
        name: 'DisplayResolution',
        description: 'Display resolution for application',
        regex: '^[a-zA-Z0-9]*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group-optional',
        label: 'Display Rotation',
        name: 'DisplayRotation',
        description: 'Display rotation for application',
        regex: '^[a-zA-Z0-9]*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group-optional',
        label: 'Display Reflection',
        name: 'DisplayReflection',
        description: 'Display reflection for application',
        regex: '^[a-zA-Z0-9]*',
        defaultValue: '',
    },
];
