"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uwc_comet_fleet_provision_inputs = void 0;
exports.uwc_comet_fleet_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Example: 101',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Site',
        name: 'Site',
        description: 'Example: BFI1',
        regex: '^[A-Z0-9]+$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'FleetId',
        name: 'FleetId',
        description: 'UUID of the UWC fleet. Example: f247010a-e1f9-43ab-99d2-b6db064a019c',
        regex: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
        defaultValue: '',
    },
];
