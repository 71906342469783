"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bcp_provision_inputs = void 0;
exports.bcp_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Example: 101',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
];
