"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autosal_provision_inputs = void 0;
exports.autosal_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'EDAMS Device Group',
        name: 'EDAMSDeviceGroup',
        description: 'Device Group in EDAMS (ex: Beta, Prod-NA) Please note: this value is case-sensitive',
        regex: '^[a-zA-Z0-9\\-]*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'NTP Servers',
        name: 'NtpServers',
        description: 'NTP Server. Please provide only one ntp server here. Default: ntp.otie.fc.a2z.com',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[-A-Za-z0-9]+\\.)+[A-Za-z]{2,})*$',
        defaultValue: 'ntp.otie.fc.a2z.com',
    },
    {
        component: 'provision-input-group',
        label: 'DNS Servers',
        name: 'DnsServers',
        description: 'DNS server. Please provide only one dns server here',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[-A-Za-z0-9]+\\.)+[A-Za-z]{2,})*$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'VLan Ip Address',
        name: 'VLANIp',
        description: 'VLan Ip Address.',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]))$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'VLan Subnet',
        name: 'VLANSubnet',
        description: 'VLan Subnet.',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]))$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'VLan Gateway',
        name: 'VLANGateway',
        description: 'VLAN Gateway.',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]))$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Private Net Ip',
        name: 'PrivateNetIp',
        description: 'Private Net Ip. Default: 11.200.0.20',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]))$',
        defaultValue: '11.200.0.20',
    },
    {
        component: 'provision-input-group',
        label: 'Private Net Subnet',
        name: 'PrivateNetSubnet',
        description: 'Private Net Subnet. Default: 255.255.255.0',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]))$',
        defaultValue: '255.255.255.0',
    },
];
