"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adta_provision_inputs = void 0;
exports.adta_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'EDAMS Device Group',
        name: 'EDAMSDeviceGroup',
        description: 'Device Group in EDAMS (ex: Beta, Prod-NA) Please note: this value is case-sensitive',
        regex: '^[a-zA-Z0-9\\-]*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'NTP Servers',
        name: 'NtpServers',
        description: 'NTP Server. Please provide only one ntp server here',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[-A-Za-z0-9]+\\.)+[A-Za-z]{2,})*$',
        defaultValue: '10.42.176.27',
    },
];
