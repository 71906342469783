"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MockedApisInstance = exports.MockedApis = void 0;
const base_apis_1 = require("../base_apis");
const functions_1 = require("../../common/functions");
const CONSTANTS = __importStar(require("../../common/constants"));
const MOCK_API_SLEEP_TIME_IN_MS = 1000;
class MockedApis extends base_apis_1.Apis {
    async listProvisionDetails(input) {
        await (0, functions_1.delay)(MOCK_API_SLEEP_TIME_IN_MS);
        const processId = input.processId;
        const nodeId = processId === CONSTANTS.ZERO_TOUCH_PROVISION ? 'WaitingRoom' : 'IND1';
        return new Promise((resolve) => {
            resolve([
                {
                    userName: 'unknown',
                    authDeviceCode: '5cb3af24-2028-41cf-a60d-5ad51b570a10',
                    provisionStartDate: input.queryStartTime,
                    hardwareId: '4C:22:FB:B1:BD:2E',
                    userCode: '3AbFrgkU',
                    nodeId: nodeId,
                    processId: processId,
                    isReprovision: 'False',
                },
                {
                    userName: 'unknown',
                    authDeviceCode: '5cd6ef24-2028-41cf-a60d-5ad5c2b7023a',
                    provisionStartDate: new Date(0.5 * (input.queryStartTime.getTime() + input.queryEndTime.getTime())),
                    hardwareId: '4C:22:F2:B1:BD:3E',
                    userCode: '3AbDBgkU',
                    nodeId: nodeId,
                    processId: processId,
                    isReprovision: 'False',
                },
                {
                    userName: 'unknown',
                    authDeviceCode: '5cd6ef24-2028-41cf-a60d-5ad5c2b7023c',
                    provisionStartDate: input.queryEndTime,
                    hardwareId: '4C:22:F2:B1:BD:3E',
                    userCode: '3AbvE2kU',
                    nodeId: nodeId,
                    processId: processId,
                    isReprovision: 'True',
                },
                {
                    userName: 'unknown',
                    authDeviceCode: '4adeff44-2028-41cf-a60d-5ad5c2b7045d',
                    provisionStartDate: input.queryEndTime,
                    hardwareId: '4C:22:F2:B1:BD:3C',
                    userCode: '3AbvE2kU',
                    nodeId: nodeId,
                    processId: processId,
                    isReprovision: 'True',
                },
            ]);
        });
    }
    async getProvisionStatus(input) {
        let output;
        if (input.authDeviceCode === '5cb3af24-2028-41cf-a60d-5ad51b570a10') {
            output = {
                hardwareId: '4C:22:F2:B1:BD:3E',
                provisionStatus: 'DeviceRegistered',
            };
        }
        else if (input.authDeviceCode === '5cd6ef24-2028-41cf-a60d-5ad5c2b7023a') {
            output = {
                hardwareId: '4C:22:F2:B1:BD:3E',
                provisionStatus: 'Execution_Succeed',
                deviceId: 'IND1-Test-101',
                nodeId: 'IND1',
                provisionInput: {
                    DnsServers: '10.4.4.10',
                    NtpServers: '10.4.4.10',
                    EDAMSDeviceGroup: 'Beta',
                },
                instanceId: 'mi-0ef631032d7965a7c',
                executionId: '704d3578-8a09-402b-8bb2-2624a999165a',
                executionSteps: [
                    {
                        name: 'EDM_OSPatching',
                        status: 'Success',
                        executionId: '1c7d00f4-ac34-4a73-bbc4-257beeeba7a9',
                        action: 'aws:executeAutomation',
                        outputs: {
                            Status: ['Success'],
                            ClientToken: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                            ExecutionId: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                        },
                    },
                    {
                        name: 'EDM_CodeSysInstallation',
                        status: 'Success',
                        executionId: '2f83902c-60c3-4a31-acb8-b49912300049',
                        action: 'aws:executeAutomation',
                        outputs: {
                            Status: ['Success'],
                            ClientToken: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                            ExecutionId: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                        },
                    },
                    {
                        name: 'EDM_ConfigureDHCP',
                        status: 'Success',
                        executionId: '2f83902c-60c3-4a31-cfg8-b49912311156',
                        action: 'aws:runCommand',
                        outputs: {
                            Status: ['Success'],
                            OutputPayload: [
                                '{"Status": "Success","CommandId": "fd47e2a9-83cd-4597-927d-2ae18c75b60a"}',
                            ],
                            CommandId: ['fd47e2a9-83cd-4597-927d-2ae18c75b60a'],
                        },
                    },
                    {
                        name: 'EDM_AwsCliInstallation',
                        status: 'Success',
                        executionId: '3b4ee4ac-4f9c-40de-8d87-c5beb785b7aa',
                        action: 'aws:executeAutomation',
                        outputs: {
                            Status: ['Success'],
                            ClientToken: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                            ExecutionId: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                        },
                    },
                    {
                        name: 'EDM_L2Deployment',
                        status: 'Pending',
                        executionId: '3b4ee4ac-4f9c-40de-8d87-c5beb78567aa',
                        action: 'aws:executeAutomation',
                    },
                ],
            };
        }
        else if (input.authDeviceCode === '5cd6ef24-2028-41cf-a60d-5ad5c2b7023c') {
            output = {
                hardwareId: '4C:22:F2:B1:BD:3E',
                provisionStatus: 'Execution_Failed',
                deviceId: 'IND1-Test-101',
                nodeId: 'IND1',
                provisionInput: {
                    DnsServers: '10.4.4.10',
                    NtpServers: '10.4.4.10',
                    EDAMSDeviceGroup: 'Beta',
                },
                instanceId: 'mi-0ed273032d7965a7c',
                executionId: '704d3578-8a09-402b-8bb2-2624a99de15a',
                executionSteps: [
                    {
                        name: 'EDM_OSPatching',
                        status: 'Success',
                        executionId: '1c7d00f4-ac34-eb73-bbc4-257beeeba7a9',
                        action: 'aws:executeAutomation',
                        outputs: {
                            Status: ['Success'],
                            ClientToken: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                            ExecutionId: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                        },
                    },
                    {
                        name: 'EDM_CodeSysInstallation',
                        status: 'Failed',
                        executionId: '2f83902c-60c3-4a31-cfg8-b49912300049',
                        action: 'aws:executeAutomation',
                        outputs: {
                            Status: ['Failed'],
                            ClientToken: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                            ExecutionId: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                        },
                    },
                    {
                        name: 'EDM_ConfigureDHCP',
                        status: 'Pending',
                        executionId: '2f83902c-60c3-4a31-cfg8-b49912311156',
                        action: 'aws:runCommand',
                    },
                    {
                        name: 'EDM_AwsCliInstallation',
                        status: 'Pending',
                        executionId: '3b4ee4ac-4f9c-40de-8d87-c2dv4d85b7aa',
                        action: 'aws:executeAutomation',
                    },
                    {
                        name: 'EDM_L2Deployment',
                        status: 'Pending',
                        executionId: '3b4ee4ac-4f9c-40de-8d87-c5beb78567aa',
                        action: 'aws:executeAutomation',
                    },
                ],
            };
        }
        else if (input.authDeviceCode === '4adeff44-2028-41cf-a60d-5ad5c2b7045d') {
            output = {
                hardwareId: '4C:22:F2:B1:BD:3E',
                provisionStatus: 'Execution_Failed',
                deviceId: 'IND1-Test-101',
                nodeId: 'IND1',
                provisionInput: {
                    DnsServers: '10.4.4.10',
                    NtpServers: '10.4.4.10',
                    EDAMSDeviceGroup: 'Beta',
                },
                instanceId: 'mi-0ed273032d7965a7c',
                executionId: '704d3578-8a09-402b-8bb2-2624a99de15a',
                executionSteps: [
                    {
                        name: 'EDM_OSPatching',
                        status: 'Success',
                        executionId: '1c7d00f4-ac34-eb73-bbc4-257beeeba7a9',
                        action: 'aws:executeAutomation',
                        outputs: {
                            Status: ['Success'],
                            ClientToken: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                            ExecutionId: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                        },
                    },
                    {
                        name: 'EDM_CodeSysInstallation',
                        status: 'Success',
                        executionId: '2f83902c-60c3-4a31-cfg8-b49912300049',
                        action: 'aws:executeAutomation',
                        outputs: {
                            Status: ['Success'],
                            ClientToken: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                            ExecutionId: ['835010d5-2460-4cbc-86cc-228c976f7b4c'],
                        },
                    },
                    {
                        name: 'EDM_ConfigureDHCP',
                        status: 'Failed',
                        executionId: '2f83902c-60c3-4a31-cfg8-b49912311156',
                        action: 'aws:runCommand',
                        outputs: {
                            Status: ['Failed'],
                            OutputPayload: ['{"Status": "Failed","CommandId": "fd47e2a9-83cd-4597-927d-2ae18c75b60a"}'],
                            CommandId: ['fd47e2a9-83cd-4597-927d-2ae18c75b60a'],
                        },
                    },
                    {
                        name: 'EDM_AwsCliInstallation',
                        status: 'Pending',
                        executionId: '3b4ee4ac-4f9c-40de-8d87-c2dv4d85b7aa',
                        action: 'aws:executeAutomation',
                    },
                    {
                        name: 'EDM_L2Deployment',
                        status: 'Pending',
                        executionId: '3b4ee4ac-4f9c-40de-8d87-c5beb78567aa',
                        action: 'aws:executeAutomation',
                    },
                ],
            };
        }
        else {
            throw Error('Mocked Error!');
        }
        await (0, functions_1.delay)(MOCK_API_SLEEP_TIME_IN_MS);
        return new Promise((resolve) => {
            resolve(output);
        });
    }
    async transactionalSaveProvisionDetails(input) {
        return new Promise((resolve) => resolve({ userCode: 'ycgIrzIV' }));
    }
    async getClientInstanceInformationList(input) {
        const output = [
            {
                awsAccount: '571362764824',
                instanceId: 'mi-01197100141d1b16d',
                deviceName: 'IND1-AutoPack-101',
                pingStatus: 'ConnectionLost',
                nodeId: 'IND1',
                podId: 'Default',
                registrationDate: new Date(),
            },
            {
                awsAccount: '571362764824',
                instanceId: 'mi-05c8d8bc9577b8842',
                deviceName: 'IND1-TestClientProcess-001',
                pingStatus: 'ConnectionLost',
                nodeId: 'IND1',
                podId: 'Default',
                registrationDate: new Date(),
            },
            {
                awsAccount: '571362764824',
                instanceId: 'mi-06570d780a4215971',
                deviceName: 'BFI1-TestClientProcess-452',
                pingStatus: 'ConnectionLost',
                nodeId: 'BFI1',
                podId: 'Default',
                registrationDate: new Date(),
            },
            {
                awsAccount: '571362764824',
                instanceId: 'mi-017688c2c19602db6',
                deviceName: 'IND1-AutoSlam-001',
                pingStatus: 'ConnectionLost',
                nodeId: 'IND1',
                podId: 'Default',
                registrationDate: new Date(),
            },
        ];
        return new Promise((resolve) => {
            resolve(output);
        });
    }
    async getClientResourceTags(input) {
        const output = [
            {
                key: 'IdempotencyId',
                value: 'aa27747e-c66d',
            },
            {
                key: 'DeviceId',
                value: input.resourceId,
            },
            {
                key: 'ProcessId',
                value: input.processId,
            },
            {
                key: 'EDAMSDeviceGroup',
                value: 'Default',
            },
            {
                key: 'PodId',
                value: input.podId,
            },
        ];
        return new Promise((resolve) => {
            resolve(output);
        });
    }
    async deregisterDevice(input) {
        const output = 'Success';
        return new Promise((resolve) => {
            resolve(output);
        });
    }
    async getProcessesForUser(input) {
        const output = [
            'AutoSlam',
            'SmartPac',
            'PID',
            'AutoPack',
            'TestClientProcess',
            'WattDemoWorkcell',
            'WIGO',
        ];
        return new Promise((resolve) => {
            resolve(output);
        });
    }
    async rebootDevice(input) {
        const output = {
            automationExecutionId: '0aabc35d-8b1d-4612-9711-12816f10f068',
            skipReboot: false,
        };
        return new Promise((resolve) => {
            resolve(output);
        });
    }
    async getProvisionInputTemplateUrls(input) {
        let output;
        if (input.processId === 'TestClientProcess') {
            output = {
                templateUrls: {
                    ['Default']: 'https://edge-device-management-scripts-223986965363-us-west-2.s3.us-west-2.amazonaws.com/edm-client-access/provisioning/inputs.json',
                    ['Pod1']: 'https://edge-device-management-scripts-223986965363-us-west-2.s3.us-west-2.amazonaws.com/edm-client-access/provisioning/inputs1.json',
                    ['Pod2']: '',
                },
            };
        }
        else if (input.processId === 'AutoSlam') {
            output = {
                templateUrls: {
                    ['Alpha']: 'https://edge-device-management-scripts-223986965363-us-west-2.s3.us-west-2.amazonaws.com/edm-client-access/provisioning/inputs.json',
                    ['Beta']: '',
                },
            };
        }
        else if (input.processId === 'AutoPack') {
            output = {
                templateUrls: {
                    ['Alpha']: 'https://edge-device-management-scripts-223986965363-us-west-2.s3.us-west-2.amazonaws.com/edm-client-access/provisioning/inputs.json',
                    ['Beta']: '',
                },
            };
        }
        else if (input.processId === 'WIGO') {
            output = {
                templateUrls: {
                    ['Alpha']: 'https://edge-device-management-scripts-223986965363-us-west-2.s3.us-west-2.amazonaws.com/edm-client-access/provisioning/inputs.json',
                    ['Beta']: 'https://edge-device-management-scripts-223986965363-us-west-2.s3.us-west-2.amazonaws.com/edm-client-access/provisioning/inputs1.json',
                },
            };
        }
        else {
            throw Error('Mocked Error!');
        }
        await (0, functions_1.delay)(MOCK_API_SLEEP_TIME_IN_MS);
        return new Promise((resolve) => {
            resolve(output);
        });
    }
}
exports.MockedApis = MockedApis;
exports.MockedApisInstance = new MockedApis();
