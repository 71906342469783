"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ops_tech_edge_wgb_provision_inputs = void 0;
exports.ops_tech_edge_wgb_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Bridged Client',
        name: 'FCType',
        description: 'Enter the type of device that will connect to the wireless bridge. Input is case-sensitive. Valid inputs include: printer, wallclock, timeclock, phone, heatsensor',
        regex: '^[a-zA-Z0-9\\-\\_]*',
        defaultValue: '',
    },
];
