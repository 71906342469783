"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorMessageLocaleBundleKeyFromOperationName = exports.getDeviceVerificationUrl = exports.isProdDomain = exports.delay = exports.sizeOfObject = exports.isEmptyString = exports.getMapValue = void 0;
const CONSTANTS = __importStar(require("./constants"));
function getMapValue(map, key, defaultValue) {
    const value = map.get(key);
    return value ? value : defaultValue;
}
exports.getMapValue = getMapValue;
function isEmptyString(str) {
    return !str || str.length === 0;
}
exports.isEmptyString = isEmptyString;
function sizeOfObject(object) {
    return Object.keys(object).length;
}
exports.sizeOfObject = sizeOfObject;
function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
exports.delay = delay;
function isProdDomain() {
    const hostname = window.location.hostname;
    if (hostname.indexOf('prod-eu') !== -1 || hostname.indexOf('prod-na') !== -1) {
        return true;
    }
    return false;
}
exports.isProdDomain = isProdDomain;
function getDeviceVerificationUrl(userCode) {
    const baseHref = window.location.hostname;
    let baseVerificationUrl = CONSTANTS.DEFAULT_VERIFICATION_URL;
    CONSTANTS.VERIFICATION_URL_MAP.forEach((value, key) => {
        if (baseHref.indexOf(key) != -1) {
            baseVerificationUrl = value;
        }
    });
    baseVerificationUrl = baseVerificationUrl + '&user_code=' + userCode;
    console.log(baseVerificationUrl);
    return baseVerificationUrl;
}
exports.getDeviceVerificationUrl = getDeviceVerificationUrl;
function getErrorMessageLocaleBundleKeyFromOperationName(operationName) {
    switch (operationName) {
        case CONSTANTS.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW_DEVICES_IN:
            return 'unauthorized-user-view-devices-message';
        case CONSTANTS.BINDLE_DEVICE_GROUP_OPERATION_CAN_PROVISION_DEVICES_IN:
            return 'unauthorized-user-provision-message';
        case CONSTANTS.BINDLE_DEVICE_GROUP_OPERATION_CAN_MODIFY_DEVICES_IN:
            return 'unauthorized-user-modify-devices-message';
        default:
            return 'unauthorized-user-default-message';
    }
}
exports.getErrorMessageLocaleBundleKeyFromOperationName = getErrorMessageLocaleBundleKeyFromOperationName;
