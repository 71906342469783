"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cls_argus_sensor_mgmt_provision_inputs = void 0;
exports.cls_argus_sensor_mgmt_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: '3 digit number to identify the device - Example: 101, 001. Need to be unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'EDAMS Device Group',
        name: 'EDAMSDeviceGroup',
        description: 'Device Group in EDAMS (ex: Beta, Prod-NA) Please note: this value is case-sensitive',
        regex: '^[a-zA-Z0-9\\-]*',
        defaultValue: '',
    },
];
