"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceDetailsKeyValues = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const react_arb_tools_1 = require("@amzn/react-arb-tools");
const apis_1 = require("../../../service/apis");
const value_with_label_1 = require("../../common/value_with_label");
const CONSTANTS = __importStar(require("../../../common/constants"));
const device_actions_dropdown_1 = require("../deviceFleet/device_actions_dropdown");
const process_helpers_1 = require("../../../common/process_helpers");
const constants_1 = require("../../../common/constants");
const functions_1 = require("../../../common/functions");
const DEVICE_DETAILS_TAGS = new Map([
    ['EDAMSDeviceGroup', 'EDAMS Device Group'],
    ['HardwareId', 'Hardware ID'],
    ['MECHInstanceId', 'MECH Instance ID'],
    ['OSSnapshotId', 'OS Snapshot ID'],
    ['EDMVersionDeployed', 'EDM Version Deployed'],
]);
const DeviceDetailsKeyValues = (props) => {
    const [localeBundle] = (0, react_arb_tools_1.useBundle)(CONSTANTS.STRINGS_NAMESPACE);
    const [localeBundleLoaded, setLocaleBundleLoaded] = (0, react_1.useState)(false);
    const [deviceTags, setDeviceTags] = (0, react_1.useState)([]);
    const process = (0, process_helpers_1.useProcess)();
    (0, react_1.useEffect)(() => {
        if (localeBundle !== undefined) {
            setLocaleBundleLoaded(true);
        }
    });
    (0, react_1.useEffect)(() => {
        if (localeBundleLoaded) {
            (0, apis_1.getApis)()
                .getClientResourceTags({
                processId: process.selectedProcess,
                podId: props.deviceInfo.podId,
                resourceId: props.deviceInfo.instanceId,
                resourceType: 'ManagedInstance',
            })
                .then((response) => {
                setDeviceTags(response);
            })
                .catch((error) => {
                const errorHeader = error.response != null && (error.response.status == 403 || error.response.status == 401)
                    ? localeBundle.getMessage('unauthorized-user')
                    : localeBundle.getMessage('something-wrong');
                let errorContent;
                if (error.response == null) {
                    errorContent = localeBundle.getMessage('refresh-page');
                }
                else {
                    errorContent =
                        error.response.status == 403 || error.response.status == 401
                            ? `${localeBundle.formatMessage((0, functions_1.getErrorMessageLocaleBundleKeyFromOperationName)(constants_1.EDM_API_TO_DEVICE_GROUP_OPERATION_MAP.get(constants_1.EDM_GET_CLIENT_RESOURCE_TAGS_API_NAME)), {
                                wikiLink: CONSTANTS.AUTHZ_DOCUMENTATION_WIKI_LINK,
                            })} Error: ${error.response.data.message}`
                            : `Error: ${error.response.data.message} ${localeBundle.getMessage('refresh-page')}`;
                }
                props.setNotification([
                    {
                        header: errorHeader,
                        type: 'error',
                        content: errorContent,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => props.setNotification([]),
                    },
                ]);
                if (error.response) {
                    console.log(`Failed to get resource tags due to ${error.response.data.message}`);
                }
                else {
                    console.log(`Failed to get resource tags due to ${JSON.stringify(error)}`);
                }
            });
        }
    }, [localeBundleLoaded]);
    const getHardwareIdFromTags = () => {
        for (const { key, value } of deviceTags) {
            if (key === 'HardwareId') {
                return value;
            }
        }
        return '';
    };
    return ((0, jsx_runtime_1.jsx)(awsui_components_react_1.Container, { header: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Header, { variant: "h2", actions: (0, jsx_runtime_1.jsx)(awsui_components_react_1.SpaceBetween, { direction: "horizontal", size: "xl", children: (0, jsx_runtime_1.jsx)(device_actions_dropdown_1.DeviceActionsDropdown, { setNotification: props.setNotification, 
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onDeregisterSuccess: () => { }, selectedDevices: [props.deviceInfo], hardwareId: getHardwareIdFromTags() }) }), children: props.deviceInfo.deviceName }), children: (0, jsx_runtime_1.jsx)(awsui_components_react_1.SpaceBetween, { size: "l", children: deviceTags.map((tag) => {
                if (DEVICE_DETAILS_TAGS.has(tag.key)) {
                    const label = DEVICE_DETAILS_TAGS.get(tag.key);
                    return ((0, jsx_runtime_1.jsx)(value_with_label_1.ValueWithLabel, { label: label, children: tag.value }, tag.key));
                }
            }) }) }));
};
exports.DeviceDetailsKeyValues = DeviceDetailsKeyValues;
