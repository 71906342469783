"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fc_games_provision_inputs = void 0;
exports.fc_games_provision_inputs = [
    {
        component: 'field',
        label: 'Station ID (and optional Mod)',
        name: 'workcellNumber',
        description: 'The identifier for the workstation. If the station has a Mod, provide the Mod after a hyphen. Example: 1120-A, 2300-B.',
        regex: '^[A-Za-z0-9_]{1,28}(-[ABFHT]{1})?$',
        defaultValue: '',
    },
];
