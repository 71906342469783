"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.raindrops_provision_inputs = void 0;
exports.raindrops_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group-optional',
        label: 'Network Name',
        name: 'NetworkName',
        description: 'Wifi Network name to automate connection to',
        regex: '^[a-zA-Z0-9]+$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group-optional',
        label: 'Network Password',
        name: 'NetworkPassword',
        description: 'Wifi Password to automate network connection',
        regex: '^[a-zA-Z0-9]+$',
        defaultValue: '',
    }
];
