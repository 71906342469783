"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const react_arb_tools_1 = require("@amzn/react-arb-tools");
const CONSTANTS = __importStar(require("../../../common/constants"));
function ProvisionInput(props) {
    const [localeBundle] = (0, react_arb_tools_1.useBundle)(CONSTANTS.STRINGS_NAMESPACE);
    if (localeBundle === undefined) {
        return (0, jsx_runtime_1.jsx)(awsui_components_react_1.Spinner, {});
    }
    if (props.provisionStatus) {
        return ((0, jsx_runtime_1.jsx)(awsui_components_react_1.Container, { header: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Header, { variant: "h2", children: localeBundle.getMessage('provision-input') }), children: props.provisionStatus.provisionInput ? ((0, jsx_runtime_1.jsx)(awsui_components_react_1.ColumnLayout, { columns: 2, borders: "vertical", children: Object.entries(props.provisionStatus.provisionInput).map(([key, value]) => {
                    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { variant: "awsui-key-label", children: key }), value] }, key));
                }) })) : ((0, jsx_runtime_1.jsx)("div", { children: localeBundle.getMessage('no-provision-input') })) }));
    }
    else {
        return ((0, jsx_runtime_1.jsx)(awsui_components_react_1.Container, { header: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Header, { variant: "h2", children: localeBundle.getMessage('provision-input') }), children: (0, jsx_runtime_1.jsx)(awsui_components_react_1.StatusIndicator, { type: "loading", children: localeBundle.getMessage('loading') }) }));
    }
}
exports.default = ProvisionInput;
