"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autopack_provision_inputs = void 0;
exports.autopack_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{1,12}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'EDAMS Device Group',
        name: 'EDAMSDeviceGroup',
        description: 'Device Group in EDAMS (ex: Beta, ProdNa) Please note: this value is case-sensitive',
        regex: '^[a-zA-Z0-9\\-]*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Workcell Mode',
        name: 'WorkcellMode',
        description: ' The workcell mode to be set for the workcell environment (ex: smartpac) Please note: this value is case-sensitive',
        regex: '^(cw1000|smartpac|genesys|cw1000slam4|ecopac|url|autobag850s|kobukuro)$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'NTP Servers',
        name: 'NtpServers',
        description: 'NTP Server. Please provide only one ntp server here. Default: ntp.otie.fc.a2z.com',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[-A-Za-z0-9]+\\.)+[A-Za-z]{2,})*$',
        defaultValue: 'ntp.otie.fc.a2z.com',
    },
    {
        component: 'provision-input-group',
        label: 'Kiosk URL',
        name: 'KioskUrl',
        description: "URL for Kiosk display. It is only for URL machine. If you are provisioning for non-URL Workcell mode, or you do not know what it is, use 'N/A'",
        regex: '^(N\\/A|https?:\\/\\/\\S+)$',
        defaultValue: 'N/A',
    },
];
