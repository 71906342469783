"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcess = exports.ProcessProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const CONSTANTS = __importStar(require("./constants"));
const apis_1 = require("../service/apis");
const functions_1 = require("./functions");
class ProcessManager {
    static getSelectedProcess() {
        const locatStorageProcess = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_PROCESS);
        if (!(0, functions_1.isEmptyString)(locatStorageProcess)) {
            return locatStorageProcess;
        }
        else {
            return CONSTANTS.PROCESS_PLACEHOLDER;
        }
    }
    static setSelectedProcess(process) {
        localStorage.setItem(CONSTANTS.LOCAL_STORAGE_PROCESS, process);
    }
}
function useProvideProcess() {
    const [isProcessesLoading, setIsProcessesLoading] = (0, react_1.useState)(true);
    const [processes, setProcesses] = (0, react_1.useState)([]);
    const [selectedProcess, setSelectedProcess] = (0, react_1.useState)(ProcessManager.getSelectedProcess());
    (0, react_1.useEffect)(() => {
        (0, apis_1.getApis)()
            .getProcessesForUser({})
            .then((response) => {
            setIsProcessesLoading(false);
            setProcesses(response);
        })
            .catch((error) => {
            setIsProcessesLoading(false);
            if (error.response) {
                console.log(`Failed to list processes due to ${error.response.data.message}`);
            }
            else {
                console.log(`Failed to list processes due to ${JSON.stringify(error)}`);
            }
        });
    }, []);
    const onSelectedProcessChange = (process) => {
        setSelectedProcess(process);
        ProcessManager.setSelectedProcess(process);
    };
    return { isProcessesLoading, processes, selectedProcess, onSelectedProcessChange };
}
const processContext = (0, react_1.createContext)({
    isProcessesLoading: true,
    processes: [''],
    selectedProcess: ProcessManager.getSelectedProcess(),
    onSelectedProcessChange: (process) => {
        return;
    },
});
function ProcessProvider({ children }) {
    const process = useProvideProcess();
    return (0, jsx_runtime_1.jsx)(processContext.Provider, { value: process, children: children });
}
exports.ProcessProvider = ProcessProvider;
function useProcess() {
    return react_1.default.useContext(processContext);
}
exports.useProcess = useProcess;
