"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autoslam_provision_inputs = void 0;
exports.autoslam_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'EDAMS Device Group',
        name: 'EDAMSDeviceGroup',
        description: 'Device Group in EDAMS (ex: Beta, Prod-NA) Please note: this value is case-sensitive',
        regex: '^[a-zA-Z0-9\\-]*',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Domain',
        name: 'Domain',
        description: 'Domain (ex: Alpha, Beta, Prod) Please note: this value is case-sensitive',
        regex: '(Alpha|Beta|Prod)',
        defaultValue: 'Prod',
    },
    {
        component: 'provision-input-group',
        label: 'Workcell Mode',
        name: 'WorkcellMode',
        description: 'The workcell mode to be set for the workcell environment (ex: AutoSlam, URL) Please note: this value is case-sensitive',
        regex: '^(AutoSlam|URL)$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'DNS Servers',
        name: 'DnsServers',
        description: 'DNS server. Please provide only one dns server here',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[-A-Za-z0-9]+\\.)+[A-Za-z]{2,})*$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'NTP Servers',
        name: 'NtpServers',
        description: 'NTP Server. Please provide only one ntp server here',
        regex: '^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[-A-Za-z0-9]+\\.)+[A-Za-z]{2,})*$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Enable DHCP',
        name: 'EnableDHCP',
        description: "Enable DHCP for VLan? Acceptable value is Yes or No. Default is 'No'. If you don't know what to do, use 'No'",
        regex: '^(Yes|No)$',
        defaultValue: 'No',
    },
    {
        component: 'provision-input-group',
        label: 'VLan Ip Address',
        name: 'VLANIp',
        description: "VLan Ip Address. If you enable DHCP for VLan (type 'Yes' in `Enable DHCP` option), use 'N/A'",
        regex: '^(N\\/A|((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])))$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'VLan Subnet',
        name: 'VLANSubnet',
        description: "VLan Subnet. If you enable DHCP for VLan (type 'Yes' in `Enable DHCP` option), use 'N/A'",
        regex: '^(N\\/A|((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])))$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'VLan Gateway',
        name: 'VLANGateway',
        description: "VLAN Gateway. If you enable DHCP for VLan (type 'Yes' in `Enable DHCP` option), use 'N/A'",
        regex: '^(N\\/A|((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])))$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Private Net Ip',
        name: 'PrivateNetIp',
        description: "Private Net Ip. If you enable DHCP for VLan (type 'Yes' in `Enable DHCP` option), use 'N/A'. Default: 11.200.0.20",
        regex: '^(N\\/A|((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])))$',
        defaultValue: '11.200.0.20',
    },
    {
        component: 'provision-input-group',
        label: 'Private Net Subnet',
        name: 'PrivateNetSubnet',
        description: "Private Net Subnet. If you enable DHCP for VLan (type 'Yes' in `Enable DHCP` option), use 'N/A'. Default: 255.255.255.0",
        regex: '^(N\\/A|((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])))$',
        defaultValue: '255.255.255.0',
    },
    {
        component: 'provision-input-group',
        label: 'Kiosk URL',
        name: 'KioskUrl',
        description: "URL for Kiosk display. It is only for URL machine. If you are provisioning for non-URL Workcell mode, or you do not know what it is, use 'N/A'",
        regex: '^(N\\/A|https?:\\/\\/\\S+)$',
        defaultValue: 'N/A',
    },
];
