"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseLayout = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const labels_1 = require("../../common/labels");
const service_navigation_1 = require("./service_navigation");
const service_header_1 = require("./service_header");
require("../../styles/base.scss");
function BaseLayout(props) {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(service_header_1.ServiceHeader, {}), (0, jsx_runtime_1.jsx)(awsui_components_react_1.AppLayout, { ...props, toolsHide: true, navigation: (0, jsx_runtime_1.jsx)(service_navigation_1.ServiceNavigation, {}), ariaLabels: labels_1.appLayoutLabels, onNavigationChange: (event) => {
                    if (props.onNavigationChange) {
                        props.onNavigationChange(event);
                    }
                }, onToolsChange: (event) => {
                    if (props.onToolsChange) {
                        props.onToolsChange(event);
                    }
                } })] }));
}
exports.BaseLayout = BaseLayout;
