"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appLayoutLabels = exports.appLayoutToolsLabels = exports.appLayoutNavigationLabels = void 0;
const appLayoutNavigationLabels = {
    navigationClose: 'Close navigation',
    navigationToggle: 'Toggle navigation',
};
exports.appLayoutNavigationLabels = appLayoutNavigationLabels;
const appLayoutToolsLabels = {
    tools: 'Tool bar',
    toolsClose: 'Close tool bar',
    toolsToggle: 'Open tool bar',
};
exports.appLayoutToolsLabels = appLayoutToolsLabels;
const appLayoutLabels = { ...appLayoutNavigationLabels, ...appLayoutToolsLabels };
exports.appLayoutLabels = appLayoutLabels;
