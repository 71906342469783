"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
function Notification(props) {
    if (props.items.length === 0) {
        return null;
    }
    else {
        return (0, jsx_runtime_1.jsx)(awsui_components_react_1.Flashbar, { items: props.items });
    }
}
exports.Notification = Notification;
