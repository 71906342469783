"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mech_provision_inputs = void 0;
exports.mech_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Workcell Number should be three digits (ex: 101) and unique under the same Process and FC',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Wireless Type',
        name: 'FCType',
        description: 'Type determines wireless properties: ssid, interface, validfor. Ref: https://code.amazon.com/packages/EdgeDeviceManagementScripts/blobs/mainline/--/scripts/Provisioning/MECH/wireless_config.json',
        regex: '^[a-zA-Z0-9\\-\\_]*',
        defaultValue: '',
    },
];
