"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProvisionStepStatusIndicator = exports.ProvisionStatusIndicator = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
function getIndicatorStatus(provisionStatus) {
    switch (provisionStatus) {
        case 'Provisioning_Failed':
        case 'Execution_Failed':
            return 'error';
        case 'DeviceRegistered':
        case 'Execution_Started':
            return 'in-progress';
        case 'Execution_Succeed':
            return 'success';
        default:
            return 'loading';
    }
}
function getStepIndicatorStatus(provisionStepStatus) {
    switch (provisionStepStatus) {
        case 'Failed':
        case 'TimedOut':
            return 'error';
        case 'Pending':
        case 'Waiting':
            return 'pending';
        case 'InProgress':
            return 'in-progress';
        case 'Success':
            return 'success';
        case 'Cancelling':
        case 'Cancelled':
            return 'stopped';
        default:
            return 'info';
    }
}
function ProvisionStatusIndicator(props) {
    return (0, jsx_runtime_1.jsx)(awsui_components_react_1.StatusIndicator, { type: getIndicatorStatus(props.provisionStatus), children: props.provisionStatus });
}
exports.ProvisionStatusIndicator = ProvisionStatusIndicator;
function ProvisionStepStatusIndicator(props) {
    return ((0, jsx_runtime_1.jsx)(awsui_components_react_1.StatusIndicator, { type: getStepIndicatorStatus(props.provisionStepStatus), children: props.provisionStepStatus }));
}
exports.ProvisionStepStatusIndicator = ProvisionStepStatusIndicator;
