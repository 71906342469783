"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_arb_tools_1 = require("@amzn/react-arb-tools");
const tokens = __importStar(require("@amzn/awsui-design-tokens"));
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const CONSTANTS = __importStar(require("../../../common/constants"));
const base_layout_1 = require("../../common/base_layout");
const process_selection_modal_1 = require("../../common/process_selection_modal");
require("./styles.scss");
function Content() {
    const [localeBundle] = (0, react_arb_tools_1.useBundle)(CONSTANTS.STRINGS_NAMESPACE);
    if (localeBundle === undefined) {
        return (0, jsx_runtime_1.jsx)(awsui_components_react_1.Spinner, {});
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(process_selection_modal_1.ProcessSelectionModal, {}), (0, jsx_runtime_1.jsx)("div", { style: { background: tokens.colorBackgroundHomeHeader }, children: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { padding: { vertical: 'xxl', horizontal: 's' }, children: (0, jsx_runtime_1.jsxs)(awsui_components_react_1.Grid, { gridDefinition: [
                            { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                            { colspan: { l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                            { colspan: { l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
                        ], children: [(0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { padding: { top: 'xs' }, fontWeight: "light", children: (0, jsx_runtime_1.jsx)("span", { className: "custom-home-text-inverted", children: localeBundle.getMessage('device-delivery') }) }), (0, jsx_runtime_1.jsxs)("div", { className: "custom-home-text-inverted", children: [(0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { variant: "h1", fontWeight: "bold", padding: "n", fontSize: "display-l", color: "inherit", children: localeBundle.getMessage('welcome') }), (0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { fontWeight: "light", padding: { bottom: 's' }, fontSize: "display-l", color: "inherit", children: localeBundle.getMessage('service-explanation') }), (0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { variant: "p", fontWeight: "light", children: (0, jsx_runtime_1.jsx)("span", { className: "custom-home-text-secondary", children: localeBundle.getMessage('platform-explanation') }) })] })] }) }) })] }));
}
function Home() {
    const [navigationOpen, setNavigationOpen] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsx)(base_layout_1.BaseLayout, { content: (0, jsx_runtime_1.jsx)(Content, {}), contentType: "default", disableContentPaddings: true, navigationOpen: navigationOpen, onNavigationChange: (event) => setNavigationOpen(event.detail.open), toolsHide: true }));
}
exports.default = Home;
