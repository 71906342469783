"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceActionsDropdown = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const react_arb_tools_1 = require("@amzn/react-arb-tools");
const CONSTANTS = __importStar(require("../../../common/constants"));
const apis_1 = require("../../../service/apis");
const process_helpers_1 = require("../../../common/process_helpers");
const functions_1 = require("../../../common/functions");
const constants_1 = require("../../../common/constants");
const DeviceActionsDropdown = (props) => {
    const [isDeregisterDisabled, setIsDeregisterDisabled] = (0, react_1.useState)(false);
    const [deregisterConfirmationModalVisible, setDeregisterConfirmationModalVisible] = (0, react_1.useState)(false);
    const [localeBundle] = (0, react_arb_tools_1.useBundle)(CONSTANTS.STRINGS_NAMESPACE);
    const [localeBundleLoaded, setLocaleBundleLoaded] = (0, react_1.useState)(false);
    const process = (0, process_helpers_1.useProcess)();
    (0, react_1.useEffect)(() => {
        if (localeBundle !== undefined) {
            setLocaleBundleLoaded(true);
        }
    });
    const successNotification = [
        {
            type: 'success',
            content: localeBundleLoaded
                ? localeBundle.getMessage('device-deregister-succeed')
                : 'Device has been successfully deregistered',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => props.setNotification([]),
        },
    ];
    const loadingNotification = [
        {
            type: 'success',
            loading: true,
            content: localeBundleLoaded
                ? localeBundle.getMessage('device-action-in-progress')
                : 'Device action is in progress...',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => props.setNotification([]),
        },
    ];
    const errorNotification = [
        {
            header: localeBundleLoaded ? localeBundle.getMessage('something-wrong') : 'Something went wrong.',
            type: 'error',
            content: localeBundleLoaded
                ? localeBundle.getMessage('refresh-page')
                : 'Please refresh the page. If the issue persists, please contact the service owner.',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => props.setNotification([]),
        },
    ];
    function getAuthorizationErrorNotification(apiName) {
        const operationName = constants_1.EDM_API_TO_DEVICE_GROUP_OPERATION_MAP.get(apiName);
        return [
            {
                header: localeBundleLoaded ? localeBundle.getMessage('unauthorized-user') : 'Unauthorized user',
                type: 'error',
                content: localeBundleLoaded
                    ? `${localeBundle.formatMessage((0, functions_1.getErrorMessageLocaleBundleKeyFromOperationName)(operationName), {
                        wikiLink: CONSTANTS.AUTHZ_DOCUMENTATION_WIKI_LINK,
                    })}`
                    : `User is not authorized to access this resource. To gain access, please contact the owners of this process to request permission. Refer to ${CONSTANTS.AUTHZ_DOCUMENTATION_WIKI_LINK} for more details.`,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => props.setNotification([]),
            },
        ];
    }
    const onConfirmDeregistration = () => {
        console.log('Confirm deregistration');
        onDeregisterClick();
        setDeregisterConfirmationModalVisible(false);
    };
    const onCancelDeregistration = () => {
        console.log('Cancel deregistration');
        setDeregisterConfirmationModalVisible(false);
    };
    const DeregisterConfirmationModal = (props) => {
        return ((0, jsx_runtime_1.jsxs)(awsui_components_react_1.Modal, { onDismiss: props.close, visible: props.visible, closeAriaLabel: "Close modal", footer: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { float: "right", children: (0, jsx_runtime_1.jsxs)(awsui_components_react_1.SpaceBetween, { direction: "horizontal", size: "xs", children: [(0, jsx_runtime_1.jsx)(awsui_components_react_1.Button, { onClick: () => onCancelDeregistration(), variant: "link", children: "Cancel" }), (0, jsx_runtime_1.jsx)(awsui_components_react_1.Button, { onClick: () => onConfirmDeregistration(), variant: "primary", children: "Ok" })] }) }), header: "Confirm deregister?", children: ["Are you sure you want to de-register the device ", props.device ? props.device[0].deviceName : null, "?"] }));
    };
    const onDeregisterClick = () => {
        console.log('Call Deregister API');
        const device = props.selectedDevices[0];
        setIsDeregisterDisabled(true);
        (0, apis_1.getApis)()
            .deregisterDevice({
            processId: process.selectedProcess,
            deviceId: device.deviceName,
            nodeId: device.nodeId,
            podId: device.podId,
            instanceId: device.instanceId,
            hardwareId: props.hardwareId,
        })
            .then((res) => {
            if (res === 'Success') {
                props.setNotification(successNotification);
                setIsDeregisterDisabled(false);
                props.onDeregisterSuccess(device.instanceId);
            }
        })
            .catch((err) => {
            if (err.response != null && (err.response.status == 403 || err.response.status == 401)) {
                props.setNotification(getAuthorizationErrorNotification(constants_1.EDM_DEREGISTER_DEVICE_API_NAME));
            }
            else {
                props.setNotification(errorNotification);
            }
            if (err.response) {
                console.log(`Failed to deregister device due to ${err.response.data.message}`);
            }
            else {
                console.log(`Failed to deregister device due to ${JSON.stringify(err)}`);
            }
            setIsDeregisterDisabled(false);
        });
    };
    const onRebootClick = () => {
        if (!allowReboot()) {
            console.log('Not allowed to reboot');
            props.setNotification(errorNotification);
            return;
        }
        const device = props.selectedDevices[0];
        (0, apis_1.getApis)()
            .rebootDevice({
            processId: process.selectedProcess,
            podId: device.podId,
            instanceId: device.instanceId,
        })
            .then((res) => {
            if (res.skipReboot) {
                props.setNotification(loadingNotification);
            }
            else {
                props.setNotification(successNotification);
            }
        })
            .catch((err) => {
            if (err.response != null && (err.response.status == 403 || err.response.status == 401)) {
                props.setNotification(getAuthorizationErrorNotification(constants_1.EDM_REBOOT_DEVICE_API_NAME));
            }
            else {
                props.setNotification(errorNotification);
            }
            if (err.response) {
                console.log(`Failed to reboot device due to ${err.response.data.message}`);
            }
            else {
                console.log(`Failed to reboot device due to ${JSON.stringify(err)}`);
            }
        });
    };
    const allowReboot = () => {
        return props.selectedDevices ? props.selectedDevices.length === 1 : false;
    };
    const allowDeregister = () => {
        return props.selectedDevices
            ? props.selectedDevices.length === 1 &&
                !isDeregisterDisabled &&
                (CONSTANTS.ADMIN_LIST.includes(sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_USERNAME)) ||
                    process.selectedProcess === CONSTANTS.AUTO_SLAM)
            : false;
    };
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(DeregisterConfirmationModal, { device: props.selectedDevices, visible: deregisterConfirmationModalVisible, close: () => setDeregisterConfirmationModalVisible(false) }), (0, jsx_runtime_1.jsx)(awsui_components_react_1.ButtonDropdown, { items: [
                    {
                        text: localeBundleLoaded ? localeBundle.getMessage('device-action-deregister') : 'De-register',
                        id: 'deregister',
                        disabled: !allowDeregister(),
                    },
                    {
                        text: localeBundleLoaded ? localeBundle.getMessage('device-action-reboot') : 'Reboot',
                        id: 'reboot',
                        disabled: !allowReboot() || (0, functions_1.isProdDomain)(),
                    },
                    {
                        text: localeBundleLoaded
                            ? localeBundle.getMessage('device-action-login-instance')
                            : 'Login to Instance',
                        id: 'login',
                        disabled: true,
                    },
                ], onItemClick: (event) => {
                    if (event.detail.id === 'deregister') {
                        if (!allowDeregister()) {
                            console.log('Not allowed to deregister');
                            return;
                        }
                        setDeregisterConfirmationModalVisible(true);
                    }
                    else if (event.detail.id === 'reboot') {
                        onRebootClick();
                    }
                    else if (event.detail.id === 'login') {
                        console.log('login');
                    }
                }, children: localeBundleLoaded ? localeBundle.getMessage('device-actions') : 'Device Actions' })] }));
};
exports.DeviceActionsDropdown = DeviceActionsDropdown;
