"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeRangeSelector = exports.convertTimeRangeValueToDate = exports.RelativeOptionSixHours = exports.RelativeOptionOneHour = exports.RelativeOptionThirtyMinutes = exports.RelativeOptionFiveMinutes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const date_fns_1 = require("date-fns");
exports.RelativeOptionFiveMinutes = {
    key: 'previous-5-minutes',
    amount: 5,
    unit: 'minute',
    type: 'relative',
};
exports.RelativeOptionThirtyMinutes = {
    key: 'previous-30-minutes',
    amount: 30,
    unit: 'minute',
    type: 'relative',
};
exports.RelativeOptionOneHour = {
    key: 'previous-1-hour',
    amount: 1,
    unit: 'hour',
    type: 'relative',
};
exports.RelativeOptionSixHours = {
    key: 'previous-6-hours',
    amount: 6,
    unit: 'hour',
    type: 'relative',
};
function convertTimeRangeValueToDate(range) {
    if (range.type === 'absolute') {
        return {
            start: new Date(range.startDate),
            end: new Date(range.endDate),
        };
    }
    else {
        const now = new Date();
        const start = (() => {
            switch (range.unit) {
                case 'second':
                    return (0, date_fns_1.addSeconds)(now, -range.amount);
                case 'minute':
                    return (0, date_fns_1.addMinutes)(now, -range.amount);
                case 'hour':
                    return (0, date_fns_1.addHours)(now, -range.amount);
                case 'day':
                    return (0, date_fns_1.addDays)(now, -range.amount);
                case 'week':
                    return (0, date_fns_1.addWeeks)(now, -range.amount);
                case 'month':
                    return (0, date_fns_1.addMonths)(now, -range.amount);
                case 'year':
                    return (0, date_fns_1.addYears)(now, -range.amount);
            }
        })();
        return {
            start: start,
            end: now,
        };
    }
}
exports.convertTimeRangeValueToDate = convertTimeRangeValueToDate;
const differenceInDays = (dateOne, dateTwo) => {
    const milliseconds = Math.abs(new Date(dateTwo).getTime() - new Date(dateOne).getTime());
    const days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
    return days;
};
const lengthInDays = (unit, amount) => {
    switch (unit) {
        case 'second':
            return amount / (24 * 60 * 60);
        case 'minute':
            return amount / (24 * 60);
        case 'hour':
            return amount / 24;
        case 'day':
            return amount;
        case 'week':
            return amount * 7;
        case 'month':
            return amount * 30;
        case 'year':
            return amount * 365;
    }
};
const isValidRangeFunction = (range) => {
    if (!range) {
        return {
            valid: false,
            errorMessage: 'The selected date range is null. Select a start and end date for the date range.',
        };
    }
    else if (range.type === 'absolute') {
        const [startDateWithoutTime] = range.startDate.split('T');
        const [endDateWithoutTime] = range.endDate.split('T');
        if (!startDateWithoutTime || !endDateWithoutTime) {
            return {
                valid: false,
                errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.',
            };
        }
        if (new Date(range.startDate).getTime() - new Date(range.endDate).getTime() >= 0) {
            return {
                valid: false,
                errorMessage: 'The selected date range is invalid. The start date must be before the end date.',
            };
        }
        if (differenceInDays(range.startDate, range.endDate) > 14) {
            return {
                valid: false,
                errorMessage: 'The selected date range is too large. Select a range up to 14 days.',
            };
        }
    }
    else if (range.type === 'relative') {
        if (isNaN(range.amount)) {
            return {
                valid: false,
                errorMessage: 'The selected date range is incomplete. Specify a duration for the date range.',
            };
        }
        if (lengthInDays(range.unit, range.amount) >= 14) {
            return {
                valid: false,
                errorMessage: 'The selected date range is too large. Select a range up to 14 days.',
            };
        }
    }
    return { valid: true };
};
function TimeRangeSelector(props) {
    return ((0, jsx_runtime_1.jsx)(awsui_components_react_1.DateRangePicker, { isValidRange: (range) => {
            return isValidRangeFunction(range);
        }, onChange: (event) => {
            props.onRangeValueChange(event.detail);
        }, value: props.rangeValue, relativeOptions: [
            exports.RelativeOptionFiveMinutes,
            exports.RelativeOptionThirtyMinutes,
            exports.RelativeOptionOneHour,
            exports.RelativeOptionSixHours,
        ], i18nStrings: {
            todayAriaLabel: 'Today',
            nextMonthAriaLabel: 'Next month',
            previousMonthAriaLabel: 'Previous month',
            customRelativeRangeDurationLabel: 'Duration',
            customRelativeRangeDurationPlaceholder: 'Enter duration',
            customRelativeRangeOptionLabel: 'Custom range',
            customRelativeRangeOptionDescription: 'Set a custom range in the past',
            customRelativeRangeUnitLabel: 'Unit of time',
            formatRelativeRange: (e) => {
                const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                return `Last ${e.amount} ${t}`;
            },
            formatUnit: (e, t) => (1 === t ? e : `${e}s`),
            dateTimeConstraintText: 'Range must be between 0 - 14 days. Use 24 hour format.',
            relativeModeTitle: 'Relative range',
            absoluteModeTitle: 'Absolute range',
            relativeRangeSelectionHeading: 'Choose a range',
            startDateLabel: 'Start date',
            endDateLabel: 'End date',
            startTimeLabel: 'Start time',
            endTimeLabel: 'End time',
            clearButtonLabel: 'Clear',
            cancelButtonLabel: 'Cancel',
            applyButtonLabel: 'Apply',
        }, placeholder: "Filter by a date and time range", showClearButton: false }));
}
exports.TimeRangeSelector = TimeRangeSelector;
