"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setHeaderOffset = void 0;
// This function is for programmatically set padding top of the body container
// so the AppLayout could be rendered in the right position with TopNav
// See: https://code.amazon.com/packages/AWS-UI-Examples/blobs/mainline-3.0/--/src/common/sticky-top-navigation.js
function setHeaderOffset() {
    const height = document.querySelector('#h').getBoundingClientRect().height;
    const bodyContainer = document.querySelector('#b');
    bodyContainer.style.paddingTop = `${height}px`;
    document.documentElement.style.scrollPaddingTop = `${height}px`;
}
exports.setHeaderOffset = setHeaderOffset;
