"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceNavigation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const CONSTANTS = __importStar(require("../../common/constants"));
const react_arb_tools_1 = require("@amzn/react-arb-tools");
const NAVIGATION_HEADER = { text: CONSTANTS.SERVICE_ABBREVIATION_NAME, href: CONSTANTS.NAV_ROUTE_HOME };
function getNavigationItems(localeBundle) {
    let managedEdgeComputeGroupString, waitingRoomString, provisionGroupString, registerDeviceString, provisionsString, deviceManagementGroupString, deviceFleetString;
    // Return a default array of navi items if localeBundle is not loaded
    if (localeBundle === undefined) {
        managedEdgeComputeGroupString = 'ManagedEdgeCompute';
        waitingRoomString = 'WaitingRoom';
        provisionGroupString = 'Provision';
        registerDeviceString = 'Registered Device';
        provisionsString = 'Provisions';
        deviceManagementGroupString = 'Device Management';
        deviceFleetString = 'Device Fleet';
    }
    else {
        managedEdgeComputeGroupString = localeBundle.getMessage('managed-edge-compute');
        waitingRoomString = localeBundle.getMessage('waiting-room');
        provisionGroupString = localeBundle.getMessage('provision');
        registerDeviceString = localeBundle.getMessage('registered-devices');
        provisionsString = localeBundle.getMessage('provision');
        deviceManagementGroupString = localeBundle.getMessage('device-management');
        deviceFleetString = localeBundle.getMessage('device-fleet');
    }
    return [
        {
            type: 'expandable-link-group',
            text: managedEdgeComputeGroupString,
            href: CONSTANTS.NAV_ROUTE_MANAGED_EDGE_COMPUTE_WAITING_ROOM,
            items: [
                { type: 'link', text: waitingRoomString, href: CONSTANTS.NAV_ROUTE_MANAGED_EDGE_COMPUTE_WAITING_ROOM },
            ],
        },
        {
            type: 'expandable-link-group',
            text: provisionGroupString,
            href: CONSTANTS.NAV_ROUTE_PROVISION,
            items: [
                { type: 'link', text: registerDeviceString, href: CONSTANTS.NAV_ROUTE_PROVISION_REGISTERED_DEVICE },
                { type: 'link', text: provisionsString, href: CONSTANTS.NAV_ROUTE_PROVISION_PROVISIONS },
            ],
        },
        {
            type: 'expandable-link-group',
            text: deviceManagementGroupString,
            href: CONSTANTS.NAV_ROUTE_DEVICE_MANAGEMENT,
            items: [
                {
                    type: 'link',
                    text: deviceFleetString,
                    href: CONSTANTS.NAV_ROUTE_DEVICE_FLEET,
                },
            ],
        },
    ];
}
const ServiceNavigationLocal = (props) => {
    const [localeBundle] = (0, react_arb_tools_1.useBundle)(CONSTANTS.STRINGS_NAMESPACE);
    const navigationItems = getNavigationItems(localeBundle);
    return ((0, jsx_runtime_1.jsx)(awsui_components_react_1.SideNavigation, { header: NAVIGATION_HEADER, items: navigationItems, activeHref: `#${props.location.pathname}`, onFollow: (event) => {
            event.preventDefault();
            if (event.detail.href) {
                props.history.push(event.detail.href.substring(1));
            }
        } }));
};
exports.ServiceNavigation = (0, react_router_dom_1.withRouter)(ServiceNavigationLocal);
