"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.node_id_input_cell = void 0;
exports.node_id_input_cell = {
    component: 'field',
    label: 'Node ID',
    name: 'nodeId',
    description: 'Node ID to which the device belongs',
    regex: '^[a-zA-Z0-9]+$',
    defaultValue: '',
};
