"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLS_ARGUS_SENSOR_MGMT = exports.OPS_TECH_EDGE_WGB = exports.PASTA_SERVICE = exports.KARMAN = exports.MECH = exports.RAINDROPS = exports.PID = exports.ADTA = exports.WATT_DEMO_WORKCELL = exports.MDA = exports.TEST_CLIENT_PROCESS = exports.EDM_TEST_FLEET = exports.AUTO_PACK = exports.AUTO_SLAM = exports.ZERO_TOUCH_PROVISION = exports.ADMIN_LIST = exports.EMPTY_STRING = exports.TIME_IN_MINUTE = exports.NAV_ROUTE_MANAGED_EDGE_COMPUTE_WAITING_ROOM = exports.NAV_ROUTE_MANAGED_EDGE_COMPUTE = exports.NAV_ROUTE_DEVICE_FLEET = exports.NAV_ROUTE_DEVICE_MANAGEMENT = exports.NAV_ROUTE_PROVISION_PROVISIONS = exports.NAV_ROUTE_PROVISION_REGISTERED_DEVICE = exports.NAV_ROUTE_PROVISION = exports.NAV_ROUTE_HOME = exports.NAV_ROUTE_BASE = exports.ROUTE_MANAGED_EDGE_COMPUTE_WAITING_ROOM = exports.ROUTE_MANAGED_EDGE_COMPUTE_PROVISION_INPUT = exports.ROUTE_MANAGED_EDGE_COMPUTE = exports.ROUTE_PROVISION_PROVISION_INPUT = exports.ROUTE_INPUT = exports.ROUTE_DEVICE_DETAILS = exports.ROUTE_DEVICE_FLEET = exports.ROUTE_DEVICE_MANAGEMENT = exports.ROUTE_PROVISION_PROVISION_STATUS = exports.ROUTE_STATUS = exports.ROUTE_PROVISION_PROVISIONS = exports.ROUTE_PROVISION_REGISTERED_DEVICE = exports.ROUTE_PROVISION = exports.ROUTE_AUTH = exports.ROUTE_HOME = exports.LANGUAGE_MAP = exports.STRINGS_NAMESPACE = exports.DEFAULT_LANG = exports.TROUBLESHOOT_WIKI_LINK = exports.AUTHZ_DOCUMENTATION_WIKI_LINK = exports.SERVICE_WIKI_LINK = exports.SERVICE_ABBREVIATION_NAME = exports.SERVICE_NAME = void 0;
exports.EDM_DEV_DOMAIN_PORT = exports.EDM_DEV_SUPERNOVA_DOMAIN = exports.EDM_DEV_DESKTOP_DOMAIN = exports.EDM_DOMAIN = exports.EDM_API_TO_DEVICE_GROUP_OPERATION_MAP = exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_PROVISION_DEVICES_IN = exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_MODIFY_DEVICES_IN = exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW_DEVICES_IN = exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW = exports.EDM_GET_PROCESSES_FOR_USER_API_NAME = exports.EDM_REBOOT_DEVICE_API_NAME = exports.EDM_DEREGISTER_DEVICE_API_NAME = exports.EDM_GET_CLIENT_RESOURCE_TAGS_API_NAME = exports.EDM_GET_CLIENT_INSTANCES_INFORMATION_API_NAME = exports.EDM_TRANSACTIONAL_SAVE_PROVISION_DETAILS_API_NAME = exports.EDM_GET_PROVISION_STATUS_API_NAME = exports.EDM_LIST_PROVISION_DETAILS_API_NAME = exports.MOCKED_SERVICE = exports.PROCESS_PLACEHOLDER = exports.LOCAL_STORAGE_PROCESS = exports.SESSION_STORAGE_REDIRECT_BACK_SEARCH = exports.SESSION_STORAGE_REDIRECT_BACK_PATH = exports.SESSION_STORAGE_USERNAME = exports.COOKIE_EDM_LOCALE = exports.COOKIE_EDM_MOCK_TYPE = exports.EPIPHRON = exports.STRAWHAT_GRAND_FLEET = exports.UWC_CRED_ROTATIion_TEST_FLEET = exports.CLS_SYSTEMS_SEC_FLEET = exports.UWC_GEMINI_TEST_FLEET = exports.WIRELESS_BRIDGE_FLEET = exports.UWC_SAS_HANDOFF_TEST_FLEET = exports.StuBurg_E2E_Testing = exports.CAKE_FLEET = exports.AR_TASRS_WORKCELL = exports.UWC_COMET_FLEET = exports.EDM_EPS_PROVISIONING_TEST = exports.TEMPLATE_TEST_CLIENT = exports.TEST_UWC_FLEET = exports.PIES_DATA_ROUTER = exports.UWC_MEC_KIOSK = exports.MERCURY = exports.FC_GAMES = exports.FC_PIMMS = exports.PROCESS_TECH = exports.AUTO_SAL = exports.BCP = exports.RSES = exports.WIGO = exports.MOD_SORT = void 0;
exports.UNKNOWN_USERNAME = exports.DEFAULT_URL = exports.URL_API_MAP = exports.DEFAULT_VERIFICATION_URL = exports.VERIFICATION_URL_MAP = exports.DEFAULT_FIT_CLIENT_CONFIG = exports.FIT_CLIENT_CONFIGS = void 0;
const { Map, OrderedMap } = require('immutable');
exports.SERVICE_NAME = 'EdgeDeviceManagement';
exports.SERVICE_ABBREVIATION_NAME = 'EDM';
exports.SERVICE_WIKI_LINK = 'https://w.amazon.com/bin/view/AFT/EDM/';
exports.AUTHZ_DOCUMENTATION_WIKI_LINK = 'https://w.amazon.com/bin/view/AFT/EDM/ClientRunbook/HowToSetPermissionsForTheUI/';
exports.TROUBLESHOOT_WIKI_LINK = 'https://w.amazon.com/bin/view/AFT/EDM/ClientRunbook/Troubleshoot/';
exports.DEFAULT_LANG = 'en-US';
exports.STRINGS_NAMESPACE = 'strings';
// Supported Language locale - description map
exports.LANGUAGE_MAP = OrderedMap({
    'ar-AE': 'العربية (الإمارات العربية المتحدة)',
    'ar-SA': 'العربية (المملكة العربية السعودية)',
    'bg-BG': 'български (България)',
    'bn-IN': 'বাংলা (ভারত)',
    'cs-CZ': 'čeština (Česko)',
    'de-DE': 'Deutsch (Deutschland)',
    'en-US': 'English (United States)',
    'en-AE': 'English (United Arab Emirates)',
    'en-AU': 'English (Australia)',
    'en-CA': 'English (Canada)',
    'en-GB': 'English (United Kingdom)',
    'en-IN': 'English (India)',
    'en-SG': 'English (Singapore)',
    'es-AR': 'español (Argentina)',
    'es-CL': 'español (Chile)',
    'es-CO': 'español (Colombia)',
    'es-ES': 'español (España)',
    'es-MX': 'español (México)',
    'es-US': 'español (Estados Unidos)',
    'fa-AF': 'فارسی (افغانستان)',
    'fr-CA': 'français (Canada)',
    'fr-FR': 'français (France)',
    'gu-IN': 'ગુજરાતી (ભારત)',
    'hu-HU': 'magyar (Magyarország)',
    'it-IT': 'italiano (Italia)',
    'ja-JP': '日本語 (日本)',
    'kn-IN': 'ಕನ್ನಡ (ಭಾರತ)',
    'ko-KR': '한국어 (대한민국)',
    'lt-LT': 'lietuvių (Lietuva)',
    'lv-LV': 'latviešu (Latvija)',
    'ml-IN': 'മലയാളം (ഇന്ത്യ)',
    'mr-IN': 'मराठी (भारत)',
    'my-MM': 'မြန်မာ (မြန်မာ)',
    'ne-NP': 'नेपाली (नेपाल)',
    'nl-NL': 'Nederlands (Nederland)',
    'pa-IN': 'ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ, ਭਾਰਤ)',
    'pl-PL': 'polski (Polska)',
    'ps-AF': 'پښتو (افغانستان)',
    'pt-BR': 'português (Brasil)',
    'pt-PT': 'português (Portugal)',
    'ro-RO': 'română (România)',
    'ru-RU': 'русский (Россия)',
    'sk-SK': 'slovenčina (Slovensko)',
    'sl-SI': 'slovenščina (Slovenija)',
    'so-SO': 'Soomaali (Soomaaliya)',
    'sv-SE': 'svenska (Sverige)',
    'ta-IN': 'தமிழ் (இந்தியா)',
    'te-IN': 'తెలుగు (భారతదేశం)',
    'tr-TR': 'Türkçe (Türkiye)',
    'uk-UA': 'українська (Україна)',
    'ur-PK': 'اردو (پاکستان)',
    'vi-VN': 'Tiếng Việt (Việt Nam)',
    'zh-CN': '中文 (中国)',
    'zh-TW': '中文 (台灣)',
});
exports.ROUTE_HOME = '/';
exports.ROUTE_AUTH = '/auth';
exports.ROUTE_PROVISION = '/provision';
exports.ROUTE_PROVISION_REGISTERED_DEVICE = exports.ROUTE_PROVISION + '/registeredDevices';
exports.ROUTE_PROVISION_PROVISIONS = exports.ROUTE_PROVISION + '/provisions';
exports.ROUTE_STATUS = '/status';
exports.ROUTE_PROVISION_PROVISION_STATUS = exports.ROUTE_PROVISION + '/:processId/:nodeId/:userName/:deviceCode/status';
exports.ROUTE_DEVICE_MANAGEMENT = '/deviceManagement';
exports.ROUTE_DEVICE_FLEET = exports.ROUTE_DEVICE_MANAGEMENT + '/deviceFleet';
exports.ROUTE_DEVICE_DETAILS = exports.ROUTE_DEVICE_FLEET + '/deviceDetails/:podId/:nodeId/:deviceName/:instanceId';
exports.ROUTE_INPUT = '/input';
exports.ROUTE_PROVISION_PROVISION_INPUT = exports.ROUTE_PROVISION + '/:processId/:nodeId/:deviceCode/input';
exports.ROUTE_MANAGED_EDGE_COMPUTE = '/ManagedEdgeCompute';
exports.ROUTE_MANAGED_EDGE_COMPUTE_PROVISION_INPUT = exports.ROUTE_MANAGED_EDGE_COMPUTE + '/WaitingRoom/:deviceCode/input';
exports.ROUTE_MANAGED_EDGE_COMPUTE_WAITING_ROOM = exports.ROUTE_MANAGED_EDGE_COMPUTE + '/WaitingRoom';
exports.NAV_ROUTE_BASE = '#';
exports.NAV_ROUTE_HOME = exports.NAV_ROUTE_BASE + exports.ROUTE_HOME;
exports.NAV_ROUTE_PROVISION = exports.NAV_ROUTE_BASE + exports.ROUTE_PROVISION;
exports.NAV_ROUTE_PROVISION_REGISTERED_DEVICE = exports.NAV_ROUTE_BASE + exports.ROUTE_PROVISION_REGISTERED_DEVICE;
exports.NAV_ROUTE_PROVISION_PROVISIONS = exports.NAV_ROUTE_BASE + exports.ROUTE_PROVISION_PROVISIONS;
exports.NAV_ROUTE_DEVICE_MANAGEMENT = exports.NAV_ROUTE_BASE + exports.ROUTE_DEVICE_MANAGEMENT;
exports.NAV_ROUTE_DEVICE_FLEET = exports.NAV_ROUTE_BASE + exports.ROUTE_DEVICE_FLEET;
exports.NAV_ROUTE_MANAGED_EDGE_COMPUTE = exports.NAV_ROUTE_BASE + exports.ROUTE_MANAGED_EDGE_COMPUTE;
exports.NAV_ROUTE_MANAGED_EDGE_COMPUTE_WAITING_ROOM = exports.NAV_ROUTE_BASE + exports.ROUTE_MANAGED_EDGE_COMPUTE_WAITING_ROOM;
exports.TIME_IN_MINUTE = 60000;
exports.EMPTY_STRING = '';
// EDM team
exports.ADMIN_LIST = ['hajohn'];
// ProcessId's
exports.ZERO_TOUCH_PROVISION = 'ZeroTouchProvision';
exports.AUTO_SLAM = 'AutoSlam';
exports.AUTO_PACK = 'AutoPack';
exports.EDM_TEST_FLEET = 'EDMTestingFleet';
exports.TEST_CLIENT_PROCESS = 'TestClientProcess';
exports.MDA = 'MDA';
exports.WATT_DEMO_WORKCELL = 'WattDemoWorkcell';
exports.ADTA = 'ADTA';
exports.PID = 'PID';
exports.RAINDROPS = 'Raindrops';
exports.MECH = 'MECH';
exports.KARMAN = 'Karman';
exports.PASTA_SERVICE = 'PastaService';
exports.OPS_TECH_EDGE_WGB = 'OpsTechEdgeWGB';
exports.CLS_ARGUS_SENSOR_MGMT = 'CLSArgusSensorMgmt';
exports.MOD_SORT = 'ModSort';
exports.WIGO = 'WIGO';
exports.RSES = 'RSES';
exports.BCP = 'BCP';
exports.AUTO_SAL = 'AutoSAL';
exports.PROCESS_TECH = 'ProcessTech';
exports.FC_PIMMS = 'FCPIMMS';
exports.FC_GAMES = 'FCGames';
exports.MERCURY = 'Mercury';
exports.UWC_MEC_KIOSK = 'UWCMecKiosk';
exports.PIES_DATA_ROUTER = 'PiesUwcDataRouterTestFleet';
exports.TEST_UWC_FLEET = 'TestUWCFleet';
exports.TEMPLATE_TEST_CLIENT = 'TemplateTestClient';
exports.EDM_EPS_PROVISIONING_TEST = 'EDMEPSProvisioningTest';
exports.UWC_COMET_FLEET = 'UWCCometFleet';
exports.AR_TASRS_WORKCELL = 'ARTasrsWorkcell';
exports.CAKE_FLEET = 'CakeFleet';
exports.StuBurg_E2E_Testing = 'StuBurgE2ETestFleet';
exports.UWC_SAS_HANDOFF_TEST_FLEET = 'UwcSasHandoffTestFleet';
exports.WIRELESS_BRIDGE_FLEET = 'WirelessBridgeFleet';
exports.UWC_GEMINI_TEST_FLEET = 'UwcGeminiTestFleet';
exports.CLS_SYSTEMS_SEC_FLEET = 'CLSSystemsSecFleet';
exports.UWC_CRED_ROTATIion_TEST_FLEET = 'UWCCredRotateTestFleet';
exports.STRAWHAT_GRAND_FLEET = 'StrawHatGrandFleet';
exports.EPIPHRON = 'Epiphron';
// Cookies
exports.COOKIE_EDM_MOCK_TYPE = 'edm_mock_type';
exports.COOKIE_EDM_LOCALE = 'edm_locale';
// Session storage
exports.SESSION_STORAGE_USERNAME = 'username';
exports.SESSION_STORAGE_REDIRECT_BACK_PATH = 'redirect_back_path';
exports.SESSION_STORAGE_REDIRECT_BACK_SEARCH = 'redirect_back_search';
// Local storage
exports.LOCAL_STORAGE_PROCESS = 'process';
exports.PROCESS_PLACEHOLDER = 'Process';
exports.MOCKED_SERVICE = 'mocked_service';
exports.EDM_LIST_PROVISION_DETAILS_API_NAME = '/listProvisionDetails';
exports.EDM_GET_PROVISION_STATUS_API_NAME = '/getProvisionStatus';
exports.EDM_TRANSACTIONAL_SAVE_PROVISION_DETAILS_API_NAME = '/transactionalSaveProvisionDetails';
exports.EDM_GET_CLIENT_INSTANCES_INFORMATION_API_NAME = '/getClientInstancesInformation';
exports.EDM_GET_CLIENT_RESOURCE_TAGS_API_NAME = '/getClientResourceTags';
exports.EDM_DEREGISTER_DEVICE_API_NAME = '/deregisterDevice';
exports.EDM_REBOOT_DEVICE_API_NAME = '/rebootDevice';
exports.EDM_GET_PROCESSES_FOR_USER_API_NAME = '/getProcessesForUser';
exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW = 'Can view';
exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW_DEVICES_IN = 'Can view devices in';
exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_MODIFY_DEVICES_IN = 'Can modify devices in';
exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_PROVISION_DEVICES_IN = 'Can provision devices in';
exports.EDM_API_TO_DEVICE_GROUP_OPERATION_MAP = new Map([
    [exports.EDM_LIST_PROVISION_DETAILS_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_PROVISION_DEVICES_IN],
    [exports.EDM_GET_PROVISION_STATUS_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_PROVISION_DEVICES_IN],
    [exports.EDM_TRANSACTIONAL_SAVE_PROVISION_DETAILS_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_PROVISION_DEVICES_IN],
    [exports.EDM_GET_CLIENT_INSTANCES_INFORMATION_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW_DEVICES_IN],
    [exports.EDM_GET_CLIENT_RESOURCE_TAGS_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW_DEVICES_IN],
    [exports.EDM_DEREGISTER_DEVICE_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_MODIFY_DEVICES_IN],
    [exports.EDM_REBOOT_DEVICE_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_MODIFY_DEVICES_IN],
    [exports.EDM_GET_PROCESSES_FOR_USER_API_NAME, exports.BINDLE_DEVICE_GROUP_OPERATION_CAN_VIEW],
]);
// FIT token provider configs
const FIT_CLIENT_ALPHA = {
    tenantId: 'f65e1b16-152a-458c-ada7-1cad19703aeb',
    clientId: 'edm-ui-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://console.alpha.edm.aft.amazon.dev/#/auth',
    timeout: 2000,
};
const FIT_CLIENT_BETA = {
    tenantId: 'f65e1b16-152a-458c-ada7-1cad19703aeb',
    clientId: 'edm-ui-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://console.beta.edm.aft.amazon.dev/#/auth',
    timeout: 2000,
};
const FIT_CLIENT_PROD_NA = {
    tenantId: '41d6ab12-4a51-4561-ad82-ce5eaffd1d0c',
    clientId: 'edm-ui-prod-na',
    stage: 'prod',
    region: 'us-east-1',
    callbackUrl: 'https://console.prod-na.edm.aft.amazon.dev/#/auth',
    timeout: 2000,
};
const FIT_CLIENT_PROD_EU = {
    tenantId: 'e8641168-89a2-4810-a755-a8b4d4daae84',
    clientId: 'edm-ui-prod-eu',
    stage: 'prod',
    region: 'eu-west-1',
    callbackUrl: 'https://console.prod-eu.edm.aft.amazon.dev/#/auth',
    timeout: 2000,
};
const FIT_CLIENT_PROD_JP = {
    tenantId: '6165e2e2-56f0-4eb0-b3a4-9bb4a911110c',
    clientId: 'edm-ui-prod-jp',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://console.prod-jp.edm.aft.amazon.dev/#/auth',
    timeout: 2000,
};
// EDM Domain list
exports.EDM_DOMAIN = [
    'console.alpha.edm.aft.amazon.dev',
    'console.beta.edm.aft.amazon.dev',
    'console.prod-na.edm.aft.amazon.dev',
    'console.prod-eu.edm.aft.amazon.dev',
    'console.prod-jp.edm.aft.amazon.dev',
];
// EDM dev domain list
// EDM devs please add your desktop's cname or your supernova domain name to below 2 lists
// For example: yuanbowe-al2.aka.corp.amazon.com as dev desktop domain, shuold be add to EDM_DEV_DESKTOP_DOMAIN
// If you have supernova domain, add it to EDM_DEV_SUPERNOVA_DOMAIN
// For example: console.desktop.shihong.people.amazon.dev as supernova domain should be added to EDM_DEV_SUPERNOVA_DOMAIN
exports.EDM_DEV_DESKTOP_DOMAIN = [
    'yuanbowe-al2.aka.corp.amazon.com',
    'yyanlin-clouddesk.aka.corp.amazon.com',
    'adverb-dev.aka.corp.amazon.com',
    'dtrna-clouddesk.aka.corp.amazon.com',
    'shihong-clouddesk.aka.corp.amazon.com',
    'dheepin-clouddesk.aka.corp.amazon.com',
    'cjiangz-cd.aka.corp.amazon.com',
];
exports.EDM_DEV_SUPERNOVA_DOMAIN = ['console.desktop.shihong.people.amazon.dev'];
// EDM dev port
exports.EDM_DEV_DOMAIN_PORT = '8000';
exports.FIT_CLIENT_CONFIGS = new Map([
    ['console.alpha', FIT_CLIENT_ALPHA],
    ['console.beta', FIT_CLIENT_BETA],
    ['console.prod-na', FIT_CLIENT_PROD_NA],
    ['console.prod-eu', FIT_CLIENT_PROD_EU],
    ['console.prod-jp', FIT_CLIENT_PROD_JP],
]);
exports.DEFAULT_FIT_CLIENT_CONFIG = FIT_CLIENT_BETA;
// Verification URLs
const ALPHA_VERIFICATION_URL = 'https://dgzxipgznnu2n.cloudfront.net/device/verification?tenant_id=f65e1b16-152a-458c-ada7-1cad19703aeb';
const BETA_VERIFICATION_URL = 'https://dgzxipgznnu2n.cloudfront.net/device/verification?tenant_id=f65e1b16-152a-458c-ada7-1cad19703aeb';
const PROD_NA_VERIFICATION_URL = 'https://d34wrqb3xn2znf.cloudfront.net/device/verification?tenant_id=41d6ab12-4a51-4561-ad82-ce5eaffd1d0c';
const PROD_EU_VERIFICATION_URL = 'https://d1u6fq2le2uc1u.cloudfront.net/device/verification?tenant_id=e8641168-89a2-4810-a755-a8b4d4daae84';
const PROD_JP_VERIFICATION_URL = 'https://dgzxipgznnu2n.cloudfront.net/device/verification?tenant_id=6165e2e2-56f0-4eb0-b3a4-9bb4a911110c';
exports.VERIFICATION_URL_MAP = new Map([
    ['console.alpha', ALPHA_VERIFICATION_URL],
    ['console.beta', BETA_VERIFICATION_URL],
    ['console.prod-na', PROD_NA_VERIFICATION_URL],
    ['console.prod-eu', PROD_EU_VERIFICATION_URL],
    ['console.prod-jp', PROD_JP_VERIFICATION_URL],
]);
exports.DEFAULT_VERIFICATION_URL = 'https://dgzxipgznnu2n.cloudfront.net/device/verification?tenant_id=f65e1b16-152a-458c-ada7-1cad19703aeb';
// Endpoints
const ALPHA_URL = 'https://api.alpha.edm.aft.amazon.dev';
const BETA_URL = 'https://api.beta.edm.aft.amazon.dev';
const PROD_NA_URL = 'https://api.prod-na.edm.aft.amazon.dev';
const PROD_EU_URL = 'https://api.prod-eu.edm.aft.amazon.dev';
const PROD_JP_URL = 'https://api.prod-jp.edm.aft.amazon.dev';
exports.URL_API_MAP = new Map([
    ['console.alpha', ALPHA_URL],
    ['console.beta', BETA_URL],
    ['console.prod-na', PROD_NA_URL],
    ['console.prod-eu', PROD_EU_URL],
    ['console.prod-jp', PROD_JP_URL],
]);
exports.DEFAULT_URL = BETA_URL; // Change this to devo url in local stack when testing in local
// Other Constants
exports.UNKNOWN_USERNAME = 'unknown';
