"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CookiesManager = void 0;
const react_cookie_1 = require("react-cookie");
const cookies = new react_cookie_1.Cookies();
/**
 * Class for managing cookies.
 */
class CookiesManager {
    static setCookie(cookieName, cookieValue) {
        cookies.set(cookieName, cookieValue, { path: '/' });
    }
    static getCookie(cookieName) {
        return cookies.get(cookieName);
    }
    static removeCookie(cookieName) {
        cookies.remove(cookieName, { path: '/' });
    }
}
exports.CookiesManager = CookiesManager;
