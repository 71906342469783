"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_arb_tools_1 = require("@amzn/react-arb-tools");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const base_layout_1 = require("../../common/base_layout");
const bread_crumbs_1 = require("../../common/bread_crumbs");
const device_details_key_values_1 = require("./device_details_key_values");
const CONSTANTS = __importStar(require("../../../common/constants"));
const notification_1 = require("../../common/notification");
function DeviceDetails() {
    const [notification, setNotification] = (0, react_1.useState)([]);
    const { podId, nodeId, deviceName, instanceId } = (0, react_router_dom_1.useParams)();
    const deviceInfo = {
        instanceId,
        nodeId,
        deviceName,
        podId,
    };
    const [localeBundle] = (0, react_arb_tools_1.useBundle)(CONSTANTS.STRINGS_NAMESPACE);
    if (localeBundle === undefined) {
        return (0, jsx_runtime_1.jsx)(awsui_components_react_1.Spinner, {});
    }
    return ((0, jsx_runtime_1.jsx)(base_layout_1.BaseLayout, { breadcrumbs: (0, jsx_runtime_1.jsx)(bread_crumbs_1.Breadcrumbs, { items: [
                { text: localeBundle.getMessage('device-fleet'), href: CONSTANTS.NAV_ROUTE_DEVICE_FLEET },
                { text: localeBundle.getMessage('device-details'), href: '' },
            ] }), notifications: (0, jsx_runtime_1.jsx)(notification_1.Notification, { items: notification }), content: (0, jsx_runtime_1.jsx)(device_details_key_values_1.DeviceDetailsKeyValues, { setNotification: setNotification, deviceInfo: deviceInfo }), contentType: "default" }));
}
exports.default = DeviceDetails;
