"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceFleetTable = exports.EmptyTableState = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const awsui_components_react_1 = require("@amzn/awsui-components-react");
const awsui_collection_hooks_1 = require("@amzn/awsui-collection-hooks");
const react_arb_tools_1 = require("@amzn/react-arb-tools");
const CONSTANTS = __importStar(require("../../../common/constants"));
const process_helpers_1 = require("../../../common/process_helpers");
const apis_1 = require("../../../service/apis");
const device_actions_dropdown_1 = require("./device_actions_dropdown");
const functions_1 = require("../../../common/functions");
const constants_1 = require("../../../common/constants");
const DEVICE_FLEET_TABLE_PREFERENCE = {
    pageSize: 50,
    visibleContent: ['deviceName', 'deviceStatus', 'siteId', 'podId', 'awsAccount', 'provisionDate'],
};
const EmptyTableState = ({ title, subtitle, action, }) => {
    return ((0, jsx_runtime_1.jsxs)(awsui_components_react_1.Box, { textAlign: "center", color: "inherit", children: [(0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { variant: "strong", textAlign: "center", color: "inherit", children: title }), (0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { variant: "p", padding: { bottom: 's' }, color: "inherit", children: subtitle }), action] }));
};
exports.EmptyTableState = EmptyTableState;
const DeviceFleetTable = (props) => {
    const [localeBundle] = (0, react_arb_tools_1.useBundle)(CONSTANTS.STRINGS_NAMESPACE);
    const [localeBundleLoaded, setLocaleBundleLoaded] = (0, react_1.useState)(false);
    const [isTableLoading, setIsTableLoading] = (0, react_1.useState)(true);
    const [clientInstanceInfoList, setClientInstanceInfoList] = (0, react_1.useState)([]);
    const [selectedItems, setSelectedItems] = (0, react_1.useState)();
    const process = (0, process_helpers_1.useProcess)();
    const [refreshData, setRefreshData] = (0, react_1.useState)(false);
    const { items, actions, collectionProps, filterProps, paginationProps } = (0, awsui_collection_hooks_1.useCollection)(clientInstanceInfoList, {
        filtering: {
            fields: ['deviceName', 'awsAccount', 'provisionDate', 'podId'],
            empty: ((0, jsx_runtime_1.jsx)(exports.EmptyTableState, { title: "No instances", subtitle: "No instances to display.", action: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Button, { onClick: () => setRefreshData(!refreshData), children: "Reload" }) })),
            noMatch: ((0, jsx_runtime_1.jsx)(exports.EmptyTableState, { title: "No matches", subtitle: "We can\u2019t find a match.", action: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Button, { onClick: () => actions.setFiltering(''), children: "Clear filter" }) })),
        },
        pagination: { pageSize: DEVICE_FLEET_TABLE_PREFERENCE.pageSize },
        sorting: {},
        selection: {},
    });
    (0, react_1.useEffect)(() => {
        if (localeBundle !== undefined) {
            setLocaleBundleLoaded(true);
        }
    });
    (0, react_1.useEffect)(() => {
        if (localeBundleLoaded) {
            setIsTableLoading(true);
            (0, apis_1.getApis)()
                .getClientInstanceInformationList({ processId: process.selectedProcess })
                .then((response) => {
                response = response.filter((info) => {
                    return !(0, functions_1.isEmptyString)(info.deviceName);
                });
                setClientInstanceInfoList(response);
                setIsTableLoading(false);
            })
                .catch((error) => {
                setClientInstanceInfoList([]);
                const errorHeader = error.response != null && (error.response.status == 403 || error.response.status == 401)
                    ? localeBundle.getMessage('unauthorized-user')
                    : localeBundle.getMessage('something-wrong');
                let errorContent;
                if (error.response == null) {
                    errorContent = localeBundle.getMessage('refresh-page');
                }
                else {
                    errorContent =
                        error.response.status == 403 || error.response.status == 401
                            ? `${localeBundle.formatMessage((0, functions_1.getErrorMessageLocaleBundleKeyFromOperationName)(constants_1.EDM_API_TO_DEVICE_GROUP_OPERATION_MAP.get(constants_1.EDM_GET_CLIENT_INSTANCES_INFORMATION_API_NAME)), {
                                wikiLink: CONSTANTS.AUTHZ_DOCUMENTATION_WIKI_LINK,
                            })} Error: ${error.response.data.message}`
                            : `Error: ${error.response.data.message} ${localeBundle.getMessage('refresh-page')}`;
                }
                props.setNotification([
                    {
                        header: errorHeader,
                        type: 'error',
                        content: errorContent,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => props.setNotification([]),
                    },
                ]);
                setIsTableLoading(false);
                if (error.response) {
                    console.log(`Failed to list instance information due to ${error.response.data.message}`);
                }
                else {
                    console.log(`Failed to list instance information due to ${JSON.stringify(error)}`);
                }
            });
        }
    }, [refreshData, process.selectedProcess, localeBundleLoaded]);
    const onRefreshClick = () => {
        setRefreshData(!refreshData);
    };
    const onDeregisterSuccess = (instanceId) => {
        setClientInstanceInfoList((currList) => currList.filter((info) => {
            return info.instanceId !== instanceId;
        }));
        setSelectedItems(undefined);
    };
    return ((0, jsx_runtime_1.jsx)(awsui_components_react_1.Table, { ...collectionProps, columnDefinitions: [
            {
                id: 'deviceName',
                header: 'Device Name',
                cell: (info) => ((0, jsx_runtime_1.jsx)(awsui_components_react_1.Link, { href: `#/deviceManagement/deviceFleet/deviceDetails/${info.podId}/${info.nodeId}/${info.deviceName}/${info.instanceId}`, children: info.deviceName })),
                sortingField: 'deviceName',
            },
            {
                id: 'deviceStatus',
                header: 'Device Status',
                cell: (info) => info.pingStatus == 'Online' ? ((0, jsx_runtime_1.jsx)(awsui_components_react_1.StatusIndicator, { children: "Online" })) : ((0, jsx_runtime_1.jsx)(awsui_components_react_1.StatusIndicator, { type: "error", children: "Connection Lost" })),
                sortingField: 'pingStatus',
            },
            {
                id: 'siteId',
                header: 'Site ID',
                cell: (info) => info.nodeId,
                sortingField: 'nodeId',
            },
            {
                id: 'podId',
                header: 'Pod ID',
                cell: (info) => info.podId,
                sortingField: 'podId',
            },
            {
                id: 'awsAccount',
                header: 'AWS Account',
                cell: (info) => info.awsAccount,
                sortingField: 'awsAccount',
            },
            {
                id: 'provisionDate',
                header: 'Provision Date',
                cell: (info) => info.registrationDate,
                sortingField: 'registrationDate',
            },
            {
                id: 'instanceId',
                header: 'Instance Id',
                cell: (info) => info.instanceId,
                sortingField: 'instanceId',
            },
        ], items: items, loading: isTableLoading, loadingText: localeBundleLoaded ? localeBundle.getMessage('loading') : 'Loading', 
        // change selection to "multi" if the functionality is needed in the future
        selectionType: "single", selectedItems: selectedItems, onSelectionChange: ({ detail }) => setSelectedItems(detail.selectedItems), trackBy: "instanceId", visibleColumns: DEVICE_FLEET_TABLE_PREFERENCE.visibleContent, empty: (0, jsx_runtime_1.jsxs)(awsui_components_react_1.Box, { textAlign: "center", color: "inherit", children: [(0, jsx_runtime_1.jsx)("b", { children: localeBundleLoaded ? localeBundle.getMessage('no-device') : 'No device' }), (0, jsx_runtime_1.jsx)(awsui_components_react_1.Box, { padding: { bottom: 's' }, variant: "p", color: "inherit", children: localeBundleLoaded ? localeBundle.getMessage('no-device-in-fleet') : 'No device in the fleet' })] }), filter: (0, jsx_runtime_1.jsx)(awsui_components_react_1.TextFilter, { ...filterProps, filteringPlaceholder: "Filter instances" }), stickyHeader: true, header: (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Header, { variant: "h1", actions: (0, jsx_runtime_1.jsxs)(awsui_components_react_1.SpaceBetween, { direction: "horizontal", size: "xl", children: [(0, jsx_runtime_1.jsx)(awsui_components_react_1.Button, { onClick: () => onRefreshClick(), children: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Icon, { name: "refresh" }) }), (0, jsx_runtime_1.jsx)(device_actions_dropdown_1.DeviceActionsDropdown, { setNotification: props.setNotification, onDeregisterSuccess: onDeregisterSuccess, selectedDevices: selectedItems, hardwareId: '' })] }), children: localeBundleLoaded ? localeBundle.getMessage('device-fleet') : 'Device Fleet' }) }), pagination: (0, jsx_runtime_1.jsx)(awsui_components_react_1.Pagination, { ...paginationProps, ariaLabels: {
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `${pageNumber}`,
            } }) }));
};
exports.DeviceFleetTable = DeviceFleetTable;
